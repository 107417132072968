import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO.js';

const About = props => (
  <Layout>
    <SEO title="404: Pokemon Not Found" />
    <div className="border-b-2 border-black md:mt-16">
      <div className="text-right text-xl font-press-start-2p">
        <div className="mt-6 mb-4">404</div>
      </div>
    </div>
    <div className="border-b-2 border-black">
      <div className="flex text-left justify-between">
        <div className="mt-32 mb-4">
          <h1 className="font-press-start-2p inline-block">Error404</h1>
        </div>
        <div className="flex flex-col justify-center">
          <img src={`/img/missingno.png`} alt="Missingno" />
        </div>
      </div>
    </div>
    <main className="md:text-xl">
      <p>
        I'm sorry, but the Pokedex entry you are looking for could not be found.
      </p>
      <p>Perhaps you could try another castle?</p>
    </main>
  </Layout>
);

export default About;
